import React from 'react';
import { markAsSync, useController } from '../useController';
import { fromJS } from 'immutable';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';

const schema = yup.object().shape({
  assignees: yup
    .array()
    .typeError('Assignee is required.')
    .of(yup.object())
    .when('isValidationEnabled', {
      is: true,
      then: yup
        .array()
        .of(yup.object())
        .required('Assignee is required'),
    }),
  isValidationEnabled: yup.boolean(),
});

const emptyState = fromJS({
  defaultSelected: null,
  isUnassign: false,
});

const actions = { getDefaultSelected, setIsUnassign };

export function TaskBulkAssignmentModalController(taskAssignees, context, assigneeOptions, isSelectAll) {
  const formContext = useForm({
    resolver: yupResolver(schema),
    mode: 'onSubmit',
    defaultValues: { assignees: undefined, isValidationEnabled: false },
  });
  const [state, controller] = useController(actions, emptyState, context);

  React.useEffect(() => {
    controller.getDefaultSelected({ taskAssignees, assigneeOptions, isSelectAll });
  }, [taskAssignees, assigneeOptions, isSelectAll]);

  const { watch } = formContext;
  const watchAssignee = watch('assignees');

  React.useEffect(() => {
    const noAssigeeSelected = watchAssignee !== undefined && !watchAssignee.length;
    const isUnassign = (taskAssignees.length > 0 && noAssigeeSelected) || (isSelectAll && noAssigeeSelected);
    controller.setIsUnassign(isUnassign);
  }, [taskAssignees, watchAssignee, isSelectAll]);

  return { state, controller, formContext };
}

markAsSync(getDefaultSelected);
function getDefaultSelected(state, { taskAssignees, assigneeOptions, isSelectAll }) {
  if (taskAssignees.length > 1 || isSelectAll) {
    const severalAssigned = { firstName: 'Several', lastName: 'Assigned', name: 'Several Assigned' };
    return state.set('defaultSelected', severalAssigned);
  }
  const singleAssigned = assigneeOptions
    .filter(option => {
      const optionId = option.get('_id') || option.get('subAccountId');
      return taskAssignees[0] === optionId;
    })
    .get(0);
  return state.set('defaultSelected', singleAssigned);
}

markAsSync(setIsUnassign);
function setIsUnassign(state, isUnassign) {
  return state.set('isUnassign', isUnassign);
}
