import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import { Nav } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export { SideBarLinkOverlay } from './SideBarLinkOverlay';
export { SideBarLink } from './SideBarLink';
import { colors } from '../theme';
import { Brand } from './Brand';
import tile from '../../scss/sidebar-bg.png';

const { REACT_APP_WEB_HELP_TRX = 'https://kbase.tradetrax.com/' } = process.env;

export const SideBarSeparator = styled.div`
  background: ${colors.gray400};
  height: 1px;
  overflow: hidden;
  display: list-item;
`;

export const defaultProps = {};

export const propTypes = {};

export const HelpCenterLink = () => (
  <div className="nav-item">
    <Link to={{ pathname: REACT_APP_WEB_HELP_TRX }} target="_blank" className="nav-link">
      <FontAwesomeIcon icon="circle-question" />
      <span>Help Center</span>
    </Link>
  </div>
);

export const UnstyledSideBar = ({ children, className, expanded, companyName = 'Sun Master Mansorny Inc.' }) => {
  return (
    <Nav
      variant="pills"
      className={cn('flex-column', className, {
        collapsed: !expanded,
      })}
    >
      <Brand expanded={expanded} />
      <CompanyName className={cn({ 'd-none': !expanded })}>{companyName}</CompanyName>
      <CompanyNameInitials companyName={companyName} className={cn({ 'd-none': expanded })} />
      {children}
    </Nav>
  );
};

const CompanyNameInitialsUnstyled = ({ className, companyName = '' }) => {
  const initials = companyName
    .toUpperCase()
    .split(/\s/)
    .slice(0, 2)
    .map(token => token.charAt(0))
    .map((character, index) => <span key={index}>{character}</span>);
  return <div className={className}>{initials}</div>;
};

const CompanyNameInitials = styled(CompanyNameInitialsUnstyled)`
  transition: display 600ms fade;
  font-style: normal;
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 2rem;
  color: ${colors.white};
  order: 0;
  align-self: center;
  margin: 0px 0px;
  padding: 40px 20px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CompanyName = styled.section`
  transition: display 600ms fade;
  font-style: normal;
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 2rem;
  color: ${colors.white};
  flex: none;
  order: 0;
  align-self: left;
  margin: 0px 0px;
  padding: 40px 20px 20px;
  word-break: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  max-height: 140px;
`;

export const SideBar = styled(UnstyledSideBar)`
  transition: width 600ms ease;
  width: 12.5rem;
  position: fixed;
  left: 0px;
  right: -0.46px;
  top: 0px;
  bottom: 0px;
  margin-bottom: auto;
  color: ${colors.gray200};
  overflow: hidden;

  background-image: url(${tile});
  background-repeat: repeat;
  background-size: 65px 43px;
  // background-size: 125px 83px;
  background-color: rgb(51, 51, 51);

  @media (max-height: 700px) {
    display: block;
    overflow: scroll;
  }

  div.nav-item,
  a.nav-link {
    line-height: 34px;
    position: relative;
  }

  a,
  a.nav-link.active {
    text-decoration: none;
    color: ${colors.gray200};
    letter-spacing: 0.2px;
    display: flex;
    flex-direction: row;
    align-items: center;

    svg {
      margin-right: 10px;
      font-size: 18px;
      line-height: 16px;
      font-style: solid;
    }
  }

  a.nav-link.active,
  a:hover {
    color: ${colors.white};
    border-radius: 0;
    box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.2);
    background-color: ${colors.gray800} !important;
  }

  a.nav-link.active {
    svg {
      color: ${colors.green300};
    }

    &:after {
      content: '';
      border: solid 2px ${colors.green300};
      display: block;
      width: 4px;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
    }
  }

  &.collapsed {
    width: 4rem;

    a,
    a.nav-link.active {
      justify-content: center;
      svg {
        margin-right: 0;
      }
      span {
        display: none;
      }
    }
  }
`;
