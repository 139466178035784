import React from 'react';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';

export function TrxTooltip({ children, text = 'Tooltip', placement = 'bottom', ...props }) {
  return (
    <OverlayTrigger
      placement={placement}
      overlay={
        <Tooltip className="trx-tooltip" {...props}>
          {text}
        </Tooltip>
      }
    >
      {children}
    </OverlayTrigger>
  );
}
