import React from 'react';
import styled from 'styled-components';

const UnstyledContent = ({ children, expanded, ...props }) => (
  <div id="layout-content" {...props}>
    {children}
  </div>
);

export const LayoutContent = styled(UnstyledContent)`
  ${props => `
    transition: margin-left 600ms ease;
    margin-left: ${props.expanded ? '200px' : '4rem'};
    margin-top: 3.75rem;
    position: fixed;
    display: flex;
    flex-direction: column;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow-y: scroll;
  `}
`;
