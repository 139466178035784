import React from 'react';
import cn from 'classnames';
import { ToggleButtons, ToggleButton } from '../ToggleButtons';

export const ALL_TASKS_FILTER = 'all-tasks';
export const TASK_TYPE = 'task-type';

export const Filters = ({ filter, onChange, allTasks = false, tasksByType = false }) => (
  <div className="d-flex flex-row align-items-center mr-2">
    <span className="mr-3">Show Task by:</span>
    <ToggleButtons type="radio" name="option" value={filter} onChange={onChange} className="sub-section mr-2">
      <ToggleButton value={TASK_TYPE} className={cn({ 'd-none': !tasksByType })}>
        Task Type
      </ToggleButton>
      <ToggleButton value={ALL_TASKS_FILTER} className={cn({ 'd-none': !allTasks })}>
        All Tasks
      </ToggleButton>
    </ToggleButtons>
  </div>
);
