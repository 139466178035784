import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { colors } from '@tradetrax/web-common';
import { DocSize } from './DocSize';
import { formatTrx, getAssigneeBadgeClassName, isUserInactive } from '../../utils';
import { TableRowDropDown } from '../../Table/RowDropdownAction';
import { UserNameLabel } from '../../Tags';

const COL_WIDTHS = {
  span: 32,
  icon: 32,
  uploadedBy: 32 * 10,
  uploadedDate: 32 * 8,
  size: 32 * 4,
  action: 32,
};

export function ListView({ context: { controller, state }, ...rest }) {
  const { attachments } = state.toObject();

  if (attachments.get('isLoading')) return null;

  return (
    <div style={{ height: '600px', overflow: 'scroll' }}>
      <UL className="header">
        <li className="pb-1">
          <Col width={COL_WIDTHS.span} />
          <Sticky>
            <NameColumnContainerSticky className="d-inline-block mr-auto" style={{ minWidth: '345px' }}>
              Name
            </NameColumnContainerSticky>
          </Sticky>
          <Col width={COL_WIDTHS.uploadedBy} className="pl-4 ml-auto">
            Uploaded By
          </Col>
          <Col width={COL_WIDTHS.uploadedDate} className="pl-4">
            Upload Date
          </Col>
          <Col width={COL_WIDTHS.size} className="pl-4">
            Size
          </Col>
          <Col width={COL_WIDTHS.action} />
          <Col width={COL_WIDTHS.span} />
        </li>
      </UL>
      <UL>
        {attachments.map((attachment, index) => (
          <Row key={`attch-${index}`} index={index} attachment={attachment} controller={controller} {...rest} />
        ))}
      </UL>
    </div>
  );
}

const Row = ({ attachment, user, appContext, controller, getCanRemoveAndDownload, index }) => {
  const accountUsers = appContext.account.get('users');
  const isDocument = attachment.get('contentType') === 'application/pdf';
  const icon = isDocument ? 'file' : 'image';

  const MenuActions = () => {
    const { canRemove, canDownload } = getCanRemoveAndDownload(attachment);
    if (!canRemove && !canDownload) return null;
    return (
      <TableRowDropDown
        download={canDownload}
        remove={canRemove}
        onSelect={action => controller.handleAction(action, attachment)}
      />
    );
  };

  return (
    <li>
      <Sticky width={COL_WIDTHS.icon} className="font-size-18 text-muted">
        <FontAwesomeIcon icon={icon} className="pr-3" />
      </Sticky>
      <NameContainerSticky index={index}>
        <NameAttachmentContainer
          className="btn btn-link main-link d-inline-block text-truncate text-left pt-1"
          onClick={() => controller.showCarousel(attachment)}
        >
          <span className="d-inline-block  text-truncate ">{attachment.get('name')}</span>
        </NameAttachmentContainer>
      </NameContainerSticky>

      <Col width={COL_WIDTHS.uploadedBy} className="ml-auto">
        <UploadedBy
          document={attachment}
          user={user}
          maxWidth={COL_WIDTHS.uploadedBy - 64}
          accountUsers={accountUsers}
        />
      </Col>
      <Col width={COL_WIDTHS.uploadedDate}>{formatTrx(attachment.get('date'))}</Col>
      <Col width={COL_WIDTHS.size}>
        <DocSize size={attachment.get('size')} />
      </Col>
      <Col width={COL_WIDTHS.action}>
        <MenuActions />
      </Col>
    </li>
  );
};

const UploadedBy = ({ document, user, maxWidth, accountUsers }) => {
  const { createdByAccountId, createdByUserId } = document.toObject();
  const { accountId, _id: userId } = user.toObject();
  const isSameAccount = createdByAccountId === accountId;
  const className = getAssigneeBadgeClassName(createdByUserId, accountId, userId, createdByAccountId);
  const isInactive = isUserInactive(
    document.get(isSameAccount ? 'createdByUserId' : 'createdByAccountId'),
    accountUsers
  );
  return (
    <UserNameLabel className={`${className} not-permission text-truncate`} isInactive={isInactive} maxWidth={maxWidth}>
      {document.get(isSameAccount ? 'createdByUserName' : 'createdByAccountName')}
    </UserNameLabel>
  );
};

const Col = styled.div`
  ${({ width }) => `
        display: inline-block;
        width: ${width}px;
    `}
`;

const Sticky = styled.div`
  background-color: white;
  position: sticky;
  left: 20px;
  top: 0;
`;

const NameContainerSticky = styled.div`
  display: block;
  background-color: white;
  position: sticky;
  left: 40px;
  top: 0;

  &::after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: -10px;
    width: 100%;
    height: 50px;
    background-color: white;
    z-index: -1;
  }
  ${props =>
    props.index === 0 &&
    `
      &::before {
        content: '';
        display: block;
        position: absolute;
        right: 5px;
        top: -50px;
        width: 60px;
        height: 30px;
        background-color: white;
        z-index: -1;
      }
    `}
`;

const NameColumnContainerSticky = styled.div`
  background-color: white;
  display: block;
  position: sticky;
  left: 30px;
  top: 0;
`;

const UL = styled.ul`
  color: ${colors.gray400};
  list-style: none;
  display: flex;
  flex-direction: column;
  padding: 0px 0px 14px 0px;
  margin: 0px;
  min-width: 1000px;

  &.header {
    padding-bottom: 0;
    color: ${colors.gray400};
    font-weight: 700;
    font-size: 12px;
  }

  li {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 17px 30px;
    border-bottom: solid 1px ${colors.gray100};

    span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      &.btn.btn-link {
        font-weight: bold !important;
        color: #333333 !important;
        padding: 0;
      }
    }
  }

  button.action:hover:not([disabled]) {
    background-color: ${colors.activeState};
    color: ${colors.blue300} !important;
  }
`;

const NameAttachmentContainer = styled.span`
  min-width: 380px;
  @media (min-width: 1525px) {
    width: 500px;
  }
  @media (min-width: 1625px) {
    width: 600px;
  }

  & > span {
    width: 360px;
    @media (min-width: 1525px) {
      width: 95%;
    }
  }
`;
